import { httpClient } from '../utils/httpClient';
import { API_URL } from '../utils/constants';

const ENDPOINT = 'api/file/download';

// eslint-disable-next-line import/prefer-default-export
export const getFile = async (fileKey) => {
    try {
        const result = await httpClient.get(`${API_URL}/${ENDPOINT}?fileKey=${fileKey}`, {
            responseType: 'blob', // Ensure the response is treated as a binary large object
        });
        return result;
    } catch (e) {
        console.log('Error in getting export data :', e);
        return null;
    }
};



