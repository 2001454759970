/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import formatCurrency from 'utils/formatCurrency';
import convertToUSDMillion from 'utils/convertToUSDMillion';
import { FOLDER_ENTITY, ASSET_ENTITY, VIEW_ENTITY } from 'utils/constants';
import riskColor from 'utils/riskColor';
import CustomHeader from 'components/Header/CustomHeader';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';
import { DEFAULT_PAGE_DATA } from '../components.constants';

const entityNames = {
    [FOLDER_ENTITY]: 'Asset',
    [VIEW_ENTITY]: 'Folder / Group',
    [ASSET_ENTITY]: 'Asset',
};

export default function RealAssetsDashboardTable({
    loading,
    riskDataFilters,
    entityType,
    tableData,
    totalCount,
    handleClick,
    pageData,
    updateCurrentPageData,
}) {
    const { pathname } = useLocation();
    const [tableDataState, setTableDataState] = useState([]);

    // execute for when filter changes
    useEffect(() => {
        if (tableData && Array.isArray(tableData) && tableData.length > 0) {
            setTableDataState(
                tableData?.map((asset) => ({
                    ...asset,
                    totalValue: isNaN(asset?.total_value)
                        ? 'N/A'
                        : `${new Intl.NumberFormat(
                              'en-US',
                              { style: 'currency', currency: 'USD' },
                              { maximumSignificantDigits: 2 },
                          ).format(convertToUSDMillion(asset?.total_value))}m`,
                    absolutevalue: isNaN(asset?.absolute_value)
                        ? 'N/A'
                        : formatCurrency(
                              convertToUSDMillion(asset?.absolute_value),
                              riskDataFilters.riskValuePrecision,
                          ),
                    relativevalue: isNaN(asset?.relative_value)
                        ? 'N/A'
                        : `${asset?.relative_value.toFixed(riskDataFilters.riskValuePrecision)} %`,
                    color: isNaN(asset?.relative_value)
                        ? riskColor.determineColor(
                              Number(asset?.relative_value?.replace(/%/g, '')),
                              'relative',
                          )
                        : '#212C37',
                })),
            );
        } else {
            setTableDataState([]);
        }
    }, [tableData]);

    const onRowClick = useCallback((params) => {
        let selectedRowDetails;
        const rowId = params.data.entityId;

        if (entityType === 7) {
            selectedRowDetails = tableData[params.rowIndex];
            const selectedRowType =
                selectedRowDetails?.type === 'folder' ? 'viewFolderDetails' : 'viewDetails';
            handleClick(rowId, selectedRowType);
        } else if (entityType === 4 && pathname?.includes('views')) {
            handleClick(rowId, 'viewAssetDetails');
        } else {
            handleClick(rowId);
        }
    }, [tableData]);

    const handleTableSearchSubmit = (searchVal) => {
        updateCurrentPageData({
            ...pageData,
            ...DEFAULT_PAGE_DATA,
            searchText: searchVal,
            updateTableOnly: true,
        });
    };

    const handlePageChange = (newPageIndex) => {
        updateCurrentPageData({
            ...pageData,
            page: newPageIndex,
            clicked: true,
            rowsPerPage: pageData?.rowsPerPage,
        });
    };

    const handlePageSizeChange = (newPageSize) => {
        updateCurrentPageData({
            ...pageData,
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        });
    };

    const getColumns = () => {
        const columns = [
            {
                headerName: `${entityNames[entityType]} Name`,
                field: 'entity_name',
            },
            {
                headerName: `Total ${entityNames[entityType]} Value`,
                field: entityType===4?'total_value': 'totalValue',
                sortable: entityType !== VIEW_ENTITY,
                valueFormatter: (params) => entityType === 4 && Number.isNaN(params.data?.total_value)
                        ? 'N/A'
                        : `${new Intl.NumberFormat(
                              'en-US',
                              { style: 'currency', currency: 'USD' },
                              { maximumSignificantDigits: 2 },
                          ).format(convertToUSDMillion(params.data?.total_value))}m`

            },
            {
                headerName: `Modeled Average Annual ${
                    riskDataFilters.analysisType === 1 ? 'Loss' : 'Gain'
                }`,
                children: [
                    {
                        headerName: 'Relative Value',
                        field:entityType===4?'relative_value': 'relativevalue',
                        sortable: entityType !== VIEW_ENTITY,
                        valueFormatter: (params) =>
                            entityType === 4 && params.data?.totalAssets === 0 || Number.isNaN(params.data?.relative_value)
                            ? 'N/A'
                            : `${params.data?.relative_value?.toFixed(
                                  riskDataFilters.riskValuePrecision,
                              )} %`,
                    },
                    {
                        headerName: 'Absolute Value',
                        field: entityType===4?'absolute_value': 'absolutevalue',
                        sortable: entityType !== VIEW_ENTITY,
                        valueFormatter: (params) =>
                            entityType === 4 && params.data?.totalAssets === 0 || Number.isNaN(params.data?.absolute_value)
                                ? 'N/A'
                                : formatCurrency(
                                      convertToUSDMillion(params.data?.absolute_value),
                                      riskDataFilters.riskValuePrecision,
                                  ),
                    },
                ],
            },
        ];

        if (entityType !== VIEW_ENTITY) {
            columns.splice(2, 0, {
                headerName: 'Scoring Status',
                field: 'scoringStatus',
                sortable: false,
                tooltipValueGetter: (params) => {
                    const { assetsInProgress, totalAssets } = params.data;

                    return assetsInProgress
                        ? `Assets Remaining: ${assetsInProgress} of ${totalAssets}`
                        : `Complete: ${totalAssets} of ${totalAssets}`;
                },
            });
        }
        return columns;
    };

    return (
        <div>
            <CustomHeader
                searchTextPrev={pageData?.searchText}
                searchPlaceholder={`${entityNames[entityType]} Name`}
                handleSearchSubmit={handleTableSearchSubmit}
            />
            <DataTableGrid
                rowData={tableDataState}
                columnDefs={getColumns()}
                onRowClicked={onRowClick}
                loading={loading}
                totalItemsCount={totalCount}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </div>
    );
}
