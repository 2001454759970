import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import styled from '@emotion/styled';
import { Button, H2, H3, Modal, ModalContent, ModalFooter } from '@spglobal/react-components';
import { Size } from '@spglobal/koi-helpers';
import ProductList from '../ProductList';
import customerService from '../../services/customerService';
import { getRole, getNBRole } from '../../utils/user';
import DataService from '../../service/data.service';
import { PRODUCT_MANAGER } from '../../utils/constants';
import { TextBodyLg, TextBodyMd } from '../shared';
import { useApiGet } from '../../hooks/useApiGet';

const LandingPageContainer = styled.div`
    margin: 0 auto;
    padding-bottom: 150px;
    width: 1056px;
    font: var(--font-body-md);
`;

export default function LandingPage() {
    const { oktaAuth } = useOktaAuth();
    const [showDialog, setShowDialog] = useState(false);
    const service = new DataService();
    const { data: appConfigs } = useApiGet('api/appConfig', {
        keys: 'product_config:welcome_text',
    });

    const product_config = appConfigs?.results?.find((i) => i.key == 'product_config').value;
    const welcome_text = appConfigs?.results?.find((i) => i.key == 'welcome_text').value;

    var products = {
        myProducts: [],
        trialProducts: [],
    };

    const userDetails = service.getUserDetails();
    const role = getRole(
        userDetails && userDetails?.claims?.SPGGroups ? userDetails?.claims?.SPGGroups : [],
    );

    const roleNB = getNBRole(
        userDetails && userDetails?.claims?.SPGGroups ? userDetails?.claims?.SPGGroups : [],
    );

    const isSubscribedClimateRisk = role ? true : false;
    const isSubscribedNatureRisk = roleNB ? true : false;

    if (product_config) {
        const product_json_array = JSON.parse(JSON.parse(product_config));

        if (product_json_array && product_json_array.length > 0) {
            for (const product of product_json_array) {
                var isSubscribed = false;

                if (product.productName.toLowerCase() === 'climate risk')
                    isSubscribed = isSubscribedClimateRisk;

                if (product.productName.toLowerCase() === 'nature risk')
                    isSubscribed = isSubscribedNatureRisk;

                if (product.isEnabled) {
                    products.myProducts.push({
                        productName: product.productName,
                        productDescription: product.productDescription,
                        path: product.path,
                        isSubscribed: isSubscribed,
                        details: product.details,
                        isNew: product.isNew,
                        isNewText: product.isNewText,
                        isEnabled: product.isEnabled,
                        isGreyed: product.isGreyed,
                        contact: 'mailto:' + product.contact,
                    });
                }
            }
        }
    }

    const handleClose = () => {
        setShowDialog(false);
        oktaAuth.signOut();
    };

    useEffect(() => {
        try {
            (async function retrieveData() {
                if (!role && !roleNB) {
                    setShowDialog(true);
                    return;
                }
                const customers = await getCustomers();
                let count = 0;
                if (customers && !customers?.results?.length) {
                    //For these 2 roles customer assignment check is not required
                    if (!(role === PRODUCT_MANAGER) && !roleNB) {
                        setShowDialog(true);
                    }
                } else if (customers && !customers?.results?.length > 0) {
                    customers?.results.forEach((customer) => {
                        if (customer?.customer_type?.toLowerCase() === 'inactive') {
                            count += 1;
                        }
                    });
                    if (count > 0 && count === customers?.results?.length) {
                        setShowDialog(true);
                    }
                }
            })();
        } catch (e) {
            console.log('error in retrieving customers:==>', e);
        }
    }, []);

    const getCustomers = async () => customerService.getAll();

    return (
        <>
            <div className="banner-image"></div>
            <LandingPageContainer>
                <H2 className="welcome-text">Welcome to S&amp;P Global Climanomics</H2>
                <div className="spg-align-center spg-justify-center ">
                    <TextBodyLg className="spg-mt-lg spg-align-center spg-justify-center ">
                        {welcome_text}
                    </TextBodyLg>
                </div>
                <ProductList productType="" products={products.myProducts} />
            </LandingPageContainer>
            <Modal size={Size.SMALL} aria-labelledby="customized-dialog-title" isOpen={showDialog}>
                <ModalContent>
                    <TextBodyMd className="spg-mt-md">
                        You don&apos;t have active customers assigned, please contact your
                        administrator.
                    </TextBodyMd>
                    <TextBodyMd>Click Ok to Logout.</TextBodyMd>
                </ModalContent>
                <ModalFooter>
                    <Button purpose="primary" active={true} onClick={handleClose}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
