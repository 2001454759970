import React, { useEffect, useState } from 'react';
import { AgGrid } from '@spglobal/koi-grid';
import { Size } from '@spglobal/koi-helpers';
import {
    LoaderOverlay,
    LoaderPosition,
    LoaderType,
    LoadingIndicator,
    Pagination,
} from '@spglobal/react-components';
import PropTypes from 'prop-types';
import {
    DEFAULT_COL_DEF,
    DEFAULT_PAGE_SIZE,
    PAGINATION_PAGE_SIZE_OPTIONS,
} from '../../components.constants';

import './DataTableGrid.scss';

const CustomLoadingComponent = () => (
    <LoadingIndicator
        className="spg-w-100"
        type={LoaderType.CENTER}
        position={LoaderPosition.STATIC}
        overlay={LoaderOverlay.BLURRED}
    />
);

/**
 * Reusable configurable Table Grid based on KOI AgGrid
 * @constructor
 * @see {@link https://ag-grid.com/react-data-grid/getting-started/} for AgGrid documentation
 */
export default function DataTableGrid({
    columnDefs,
    rowData = [],
    frameworkComponents,
    onRowClicked,
    onGridReady,
    size,
    pagination = true,
    totalItemsCount,
    onPageChange,
    onPageSizeChange,
    onRowCheckboxSelected,
    onRowDataChanged,
    onFirstDataRendered,
    loading = false,
    className = '',
    isRowSelectable,
    resetPagination,
    onPaginationChanged
}) {
    if (!columnDefs) {
        throw new Error('DataTableGrid: "columnDefs" is required');
    }

    const [totalItems, setTotalItems] = React.useState(0);
    const [paginationPageSize, setPaginationPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [gridApi, setGridApi] = useState(null);

    useEffect(() => {
        if (loading) {
            gridApi?.showLoadingOverlay();
        } else {
            gridApi?.hideOverlay();

            if (!rowData?.length) {
                gridApi?.showNoRowsOverlay();
            }
        }
    }, [gridApi, loading]);

    useEffect(() => {
        if (totalItemsCount) {
            setTotalItems(totalItemsCount);
        } else {
            setTotalItems(rowData.length);
        }
    }, [rowData, totalItemsCount]);

    useEffect(() => {
        if (resetPagination) {
            setPaginationPageSize(DEFAULT_PAGE_SIZE);
            gridApi?.paginationGoToPage(0);
        }
    }, [resetPagination]);

    const handleChangePage = (pageNumber) => {
        gridApi?.paginationGoToPage(pageNumber - 1);

        if (onPageChange) {
            onPageChange(pageNumber - 1);
        }
    };

    const handlePageSizeChange = (pageSize) => {
        gridApi?.paginationSetPageSize(pageSize);
        setPaginationPageSize(pageSize);

        if (onPageSizeChange) {
            onPageSizeChange(pageSize);
        }
    };

    const gridOptions = {
        defaultColDef: DEFAULT_COL_DEF,
        size: size || Size.MEDIUM,
        localeText: {
            noRowsToShow: 'No data to display',
        },
        loadingOverlayComponent: 'customLoadingOverlay',
    };

    if (onRowClicked) {
        gridOptions.onRowClicked = onRowClicked;
    }

    if (onFirstDataRendered) {
        gridOptions.onFirstDataRendered = onFirstDataRendered;
    }

    if (isRowSelectable) {
        gridOptions.isRowSelectable = isRowSelectable;
    }

    gridOptions.frameworkComponents = {
        ...frameworkComponents,
        customLoadingOverlay: CustomLoadingComponent,
    };

    if (onRowCheckboxSelected) {
        gridOptions.onRowCheckboxSelected = onRowCheckboxSelected;
    }

    if (onRowDataChanged) {
        gridOptions.onRowDataChanged = onRowDataChanged;
    }

    const handleGridReady = (gridReadyEvent) => {
        setGridApi(gridReadyEvent.api);

        if (onGridReady) {
            onGridReady(gridReadyEvent);
        }
    };

    const onFilterChanged = (e) => {
        setTotalItems(e.api.getDisplayedRowCount());
    }

    return (
        <>
            <div className={`spg-mt-xl ${className}`} >
                <AgGrid
                    columnDefs={columnDefs}
                    rowData={rowData}
                    {...gridOptions}
                    onGridReady={(e) => handleGridReady(e)}
                    pagination
                    paginationPageSize={DEFAULT_PAGE_SIZE}
                    onPaginationChanged={onPaginationChanged}
                    suppressPaginationPanel // hide embedded pagination, use <Pagination/> instead
                    onFilterChanged={onFilterChanged}
                />
            </div>
            {pagination && (
                <div className="spg-mt-sm">
                    <Pagination
                        defaultPageSize={paginationPageSize}
                        totalItems={totalItems}
                        onChange={handleChangePage}
                        pageSizeOptions={PAGINATION_PAGE_SIZE_OPTIONS}
                        onChangePageSize={(pageSizeValue) => {
                            handlePageSizeChange(pageSizeValue);
                        }}
                    />
                </div>
            )}
        </>
    );
}

DataTableGrid.propTypes = {
    className: PropTypes.string,
    columnDefs: PropTypes.array,
    frameworkComponents: PropTypes.object,
    loading: PropTypes.bool,
    onFirstDataRendered: PropTypes.func,
    onGridReady: PropTypes.func,
    onPaginationChanged: PropTypes.func,
    onRowCheckboxSelected: PropTypes.func,
    onRowClicked: PropTypes.func,
    onRowDataChanged: PropTypes.func,
    pagination: PropTypes.bool,
    resetPagination: PropTypes.bool,
    rowData: PropTypes.array,
    size: PropTypes.any,
};
