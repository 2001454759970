import React from 'react';
import { Link, TableGrid } from '@spglobal/react-components';

export default function AssetFieldTable(props) {
    const AssetTypeIdDescriptionCellRenderer = () => (
        <span>
            Unique asset type identifiers. To fill out this column,{' '}
            <Link onClick={props.downloadAssetTypes}>download Asset Types</Link>
        </span>
    );

    const rows = [
        {
            name: 'Asset Name',
            type: 'Required ',
            description:
                'Unique name of the asset to be uploaded. Duplicate names will generate an error. ',
            format: 'String',
        },
        {
            name: 'Asset Reference ID',
            type: 'Required ',
            description:
                'Unique asset identifier that is used in your organization. Duplicate IDs will generate an error unless associated with an Asset that has multiple locations',
            format: 'String',
        },
        {
            name: 'Asset Value',
            type: 'Required for Financial Impact; not required for Exposure Scores. ',
            description: 'Value of the asset being uploaded. In millions USD.',
            format: 'Decimal',
        },
        {
            name: 'Asset Emissions',
            type: 'Required. Enter a value to calculate Carbon Pricing risk; enter 0 if value is unknown.',
            description: 'Greenhouse gas (GHG) emissions of the asset being uploaded. In million tons CO₂-equivalent. If provided, the system calculates the carbon pricing risk.',
            format: 'Decimal',
        },
        {
            name: 'Latitude',
            type: 'Strongly Recommended (Required if address is not available)',
            description:
                'Latitude is required for the highest level of location accuracy. If latitude and longitude coordinates are not available, you may provide the street address, city, state/province, postal code and country as the alternative inputs for geographical location. Latitude must be between -90 and +90 degrees. A value outside this range will generate an error.',
            format: 'Decimal',
        },
        {
            name: 'Longitude',
            type: 'Strongly Recommended (Required if address is not available)',
            description:
                'Longitude is required for the highest level of location accuracy. If latitude and longitude coordinates are not available, you may provide the street address, city, state/province, postal code and country as the alternative inputs for geographical location. Longitude must be between -180 and +180 degrees. A value outside this range will generate an error.',
            format: 'Decimal',
        },
        {
            name: 'Street Address',
            type: 'Recommended (Required if Lat/Long coordinates are not available)',
            description:
                'Street address for the asset consisting of the property number and street name (i.e. 10 Downing Street). Do not include extra information such as floor or office numbers. Do not enter mailing addresses such as PO box numbers. Entering a street address enhances location accuracy and is encouraged even if latitude and longitude coordinates are available.',
            format: 'String',
        },
        {
            name: 'City',
            type: 'Recommended (Required if Lat/Long coordinates are not available)',
            description:
                'City, town or municipality where the asset is located. Do not include the county or any other administrative units. Entering this information will enhance location accuracy and is encouraged even if latitude and longitude coordinates are available.',
            format: 'String',
        },
        {
            name: 'State/Province',
            type: 'Recommended (Required if Lat/Long coordinates are not available)',
            description:
                'State or province where the asset is located. Do not include the county or any other administrative units. Entering this information will enhance location accuracy and is encouraged even if latitude and longitude coordinates are available.',
            format: 'String',
        },
        {
            name: 'Postal Code',
            type: 'Recommended (Required if Lat/Long coordinates are not available)',
            description:
                'The postal code where the asset is physically located. Entering this information will enhance location accuracy and is encouraged even if latitude and longitude coordinates are available.',
            format: 'String',
        },
        {
            name: 'Country',
            type: 'Recommended (Required if Lat/Long coordinates are not available)',
            description:
                'The country where the asset is located. Entering this information will enhance location accuracy and is encouraged even if latitude and longitude coordinates are available.',
            format: 'String',
        },
        {
            name: 'Location Reference ID',
            type: 'Optional (Strongly Recommended if asset has multiple locations. If not provided, a new location ID will be created.)',
            description:
                'Unique identifier for an Asset with multiple locations. The Location Reference ID is used to either create the new location, or to update an existing location associated to the given Location Reference ID',
            format: 'String',
        },
        {
            name: 'Asset Type ID',
            type: 'Required for Financial Impact; not required for Exposure Scores.',
            description: <AssetTypeIdDescriptionCellRenderer />,
            format: 'Integer',
        },
        {
            name: 'Folder ID',
            type: 'Optional',
            description:
                'Unique identifier for folders created within your portfolio folder ID. By adding the Folder ID, assets that are successfully uploaded are immediately assigned to a Folder. For a list of your folder IDs, please go to the All Assets page (Menu > Manage (sidebar) > All Assets) and go to the Folders tab. If you have not created folders, leave this column blank and your uploaded assets will be automatically be stored in the default "Uncategorized Folder".',
            format: 'Integer',
        },
        {
            name: 'Tags',
            type: 'Optional',
            description:
                'Non-hierarchical keywords that may be used to further classify assets. You may add multiple tags to an asset in the upload template. Separate each tag with a comma.',
            format: 'String',
        },
    ];

    const columnDefs = [
        {
            title: 'Field Names',
            path: 'name',
        },
        {
            title: 'type',
            path: 'type',
        },
        {
            title: 'Description',
            path: 'description',
        },
        {
            title: 'Format',
            path: 'format',
        },
    ];
    return (
        <div>
            <TableGrid hasHeader columns={columnDefs} data={rows} />
        </div>
    );
}
