import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Button,
    Header,
    HeaderLogo,
    HeaderUserSection,
    HeaderUserSectionIcon,
    NotificationType,
    Tooltip,
    TooltipPlacement,
    TooltipTriggerEvent,
    useNotification,
    Switch,
} from '@spglobal/react-components';
import { Classes, Position, Purpose, Size } from '@spglobal/koi-helpers';
import { USER } from '@spglobal/koi-icons';
import { useOktaAuth } from '@okta/okta-react';
import UserContext from '../../context/UserContext';
import tcslogo from '../../assets/logo-spglobal.svg';
import { LogoText, TextBodyLg } from '../shared';

const SUCCESS_MSG = 'Successfully logged out of Climanomics. Redirecting in a moment...';

/**
 * Global Header of the app
 * @returns {JSX.Element}
 * @constructor
 */
export default function MainAppBar({ handleThemeChange, isDarkTheme }) {
    const { oktaAuth } = useOktaAuth();
    const { user } = useContext(UserContext);

    const { addNotification } = useNotification();

    const UserIconButton = (
        <Button>
            <HeaderUserSectionIcon size={Size.LARGE} icon={USER} />
        </Button>
    );

    const CustomHeaderLogo = (
        <div className="spg-d-flex spg-justify-between spg-align-center">
            <RouterLink to="/">
                <img alt="S&amp;P Global Climanomics" src={tcslogo} />
            </RouterLink>
            {/* <LogoText className="spg-d-flex spg-align-center spg-ml-md spg-pt-sm">
                S&amp;P Global Climanomics
            </LogoText> */}
        </div>
    );

    const handleLogout = async () => {
        oktaAuth.signOut().then(() => addNotification(SUCCESS_MSG, NotificationType.SUCCESS));
    };

    /*
    Code between AppBar and Toolbar will show an alert to user that IFs and/or asset types are
    being updated, and will show a button that redirects to WhatsNew component (the change log).
    Comment in/out if IF Notification functionality is active/inactive (monthly recurrence).
  */
    return (
        <>
            <Header isYuki={true} isStatic>
                <HeaderLogo customTemplate={CustomHeaderLogo} className="spg-ml-xl" />
                <HeaderUserSection isYuki>
                    <Tooltip
                        ref={null}
                        triggerElement={UserIconButton}
                        triggerEvent={TooltipTriggerEvent.CLICK}
                        placement={TooltipPlacement.BOTTOM}
                        isSecondary
                        contentPadding={0}
                    >
                        <div className="spg-p-md">
                            <TextBodyLg className="spg-mb-md">{user.name}</TextBodyLg>
                            <div className="spg-mb-md">
                                <Switch
                                    defaultChecked={isDarkTheme}
                                    label="Dark theme"
                                    labelPlacement={Position.LEFT}
                                    onChange={handleThemeChange}
                                />
                            </div>
                            <Button
                                onClick={handleLogout}
                                purpose={Purpose.LINK}
                                className={`${Classes.LINK} ${Classes.TEXT_MEDIUM}`}
                            >
                                Sign Out
                            </Button>
                        </div>
                    </Tooltip>
                </HeaderUserSection>
            </Header>
        </>
    );
}
