import React from 'react';
import styled from '@emotion/styled';
import ProductHeader from '../ProductHeader';
import ProductCard from '../ProductCard';
import ProductAlert from '../ProductAlert';

const ListItem = styled.li`
    list-style: none;
    padding: 0;
`;

const ListItemGreyed = styled.li`
    opacity: 0.5;
    pointer-events: none;
`;

export default function ProductList({ productType, products }) {
    return (
        <>
            {products?.length > 0 && (
                <ListItem key={productType} data-testid="product-list-container">
                    <ProductHeader headline={productType} />
                    {productType === 'Trial Products' && (
                        <ProductAlert data-testid="product-list-alert" />
                    )}
                    <ul className="spg-list" data-testid="product-list-products-list">
                        {products.map((product) => (
                            <ListItem key={product.productName} className="spg-mt-md">
                                {product.isGreyed ? (
                                    <ListItemGreyed>
                                        <ProductCard product={product} />
                                    </ListItemGreyed>
                                ) : (
                                    <ProductCard product={product} />
                                )}
                            </ListItem>
                        ))}
                    </ul>
                </ListItem>
            )}
        </>
    );
}
