import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
    Button,
    IconButton,
    Tooltip,
    TooltipPlacement,
    TooltipTriggerEvent,
} from '@spglobal/react-components';
import { Purpose, Size } from '@spglobal/koi-helpers';
import { RESTORE } from '@spglobal/koi-icons';
import { getSingleReport, downloadReport, getDownloadHistory } from '../../services/exportService';
import DataService from '../../service/data.service';
import DownloadHistory from '../DownloadHistory';
import CustomHeader from '../Header/CustomHeader';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';
import { TableDataContext, TextBodySm } from '../shared';
import { FiltersTooltipTable } from './GenerateReportTable.styles';
import {
    downloadSummaryReport,
    getSingleSummaryReport,
    getSummaryReportDownloadHistory,
} from '../../SummaryReport/services/summaryReportService';
import { SCENARIO_OPTIONS } from '../../utils/constants';

export default function GenerateReportTable({
    tableData,
    getReportList,
    updateTableState,
    count,
    pageData,
    setPageData,
    columnDefs,
    getFilterCount,
    isExportTable,
    isSummaryReportTable,
}) {
    const [searchText, setSearchText] = useState('');
    const [selectedColumnData, setSelectedColumnData] = useState({});
    const [showDownloadHistory, setShowDownloadHistory] = useState(false);
    const [downloadData, setDownloadData] = useState([]);
    const [downloadDataCount, setDownloadDataCount] = useState(0);
    const service = new DataService();

    const downloadHistory = async (rowData) => {
        setShowDownloadHistory(true);
        setSelectedColumnData(rowData);
        const data = await getDownloadHistoryList(0, 10, rowData);
        await setDownloadData(data);
    };

    const handleClose = () => {
        setShowDownloadHistory(false);
    };

    const downloadActionClicked = async (rowData, exportTableData) => {
        const token = service.getUserDetails();
        let userId = '';
        let username = '';
        if (token) {
            const {
                claims: { email, name },
            } = token;
            userId = email;
            username = name;
        }

        const reqData = {
            customer_id: rowData.customer_id,
            file_id: rowData.file_id,
            file_name: rowData.file_name,
            download_type: 'file',
        };

        const headers = {
            'X-User-Name': username,
            'X-User-Email': userId,
        };

        let response;
        if (isExportTable) {
            response = await downloadReport(rowData.file_id, reqData);
        }
        if (isSummaryReportTable) {
            response = await downloadSummaryReport(rowData.file_id, reqData, headers);
        }

        const file_name = isExportTable
            ? `${reqData.file_name.split('.')[0]}_${new Date().toUTCString()}.csv`
            : reqData.file_name;

        service.downloadFile(response.pre_signed_url, file_name, true);

        await refreshHandler(rowData, exportTableData);
    };

    const handleSubmit = async (searchVal) => {
        if (searchVal?.length) {
            setSearchText(searchVal);
            const result = await getReportList(0, pageData.rowsPerPage, null, {
                searchText: searchVal,
                searchtype: 'user_email',
            });
            updateTableState(result);
        }
    };

    const handleChangePage = (newPage) => {
        const pageInfo = {
            page: newPage,
            clicked: true,
            rowsPerPage: pageData.rowsPerPage,
        };
        setPageData(pageInfo);
    };

    const handleChangeRowsPerPage = (newPageSize) => {
        const pageInfo = {
            page: 0,
            clicked: true,
            rowsPerPage: newPageSize,
        };
        setPageData(pageInfo);
    };

    const calculateOffset = () => pageData.page * pageData.rowsPerPage;

    const refreshHandler = async (rowData, exportTableData) => {
        const reqData = {
            file_id: rowData.file_id,
            customer_id: rowData.customer_id,
        };

        let reportData;
        if (isExportTable) {
            reportData = await getSingleReport(reqData);
        }
        if (isSummaryReportTable) {
            reportData = await getSingleSummaryReport(reqData);
        }

        exportTableData?.forEach((element) => {
            if (element.file_id === rowData.file_id) {
                element.processing_status = reportData?.processing_status;
                element.download_count = reportData?.download_count;
                element.ready_for_download = reportData?.ready_for_download;
                element.file_name = reportData?.file_name;
            }
        });
        const offset = calculateOffset();
        const updatedTable = await getReportList(offset, pageData.rowsPerPage, exportTableData);
        updateTableState(updatedTable);
    };

    const getDownloadHistoryList = async (offset = 0, limit = 10, data) => {
        let res = [];
        const reqData = {
            offset,
            limit,
            download_type: 'list',
            customer_id: selectedColumnData?.customer_id || data?.customer_id,
            file_id: selectedColumnData?.file_id || data?.file_id,
        };
        reqData.sort_field = 'user_name';

        if (isExportTable) {
            res = await getDownloadHistory(reqData);
        }
        if (isSummaryReportTable) {
            res = await getSummaryReportDownloadHistory(reqData);
        }

        const totalCount = res?.count;
        setDownloadDataCount(totalCount);
        return res?.results;
    };

    useEffect(() => {
        (async function retrieveData() {
            if (pageData.clicked) {
                const offset = calculateOffset();
                const result = await getReportList(offset, pageData.rowsPerPage, null, {
                    searchtype: 'user_email',
                    searchText,
                });
                updateTableState(result);
            }
        })();
    }, [pageData]);

    const getSelectedFilters = (data, element) => {
        const scenarioOptionsOrder = SCENARIO_OPTIONS.map(option => option.label.split(' ')[0]);
        const filterElem = data?.filters[element];

        switch (element) {
            case 'scenario': {
                if (Array.isArray(filterElem) && filterElem?.length) {
                    return filterElem.sort((a, b) => scenarioOptionsOrder?.indexOf(a) - scenarioOptionsOrder?.indexOf(b)).join(', ');
                }
                return filterElem?.length ? filterElem?.toString() : 'N/A';
            }
            case 'risks': {
                const risks = JSON.parse(filterElem);
                return risks
                    ?.map((r) => {
                        if (r === 'P') return 'Physical';
                        if (r === 'T') return 'Transition';
                        if (r === 'O') return 'Opportunities';
                        return '';
                    })
                    .join(', ');
            }
            case 'summary_report_type': {
                return filterElem?.replaceAll('_', ' ') || 'N/A';
            }
            case 'decade': {
                const decade = Array.isArray(filterElem) ? filterElem : JSON.parse(filterElem);
                return Array.isArray(decade) ? decade.join(', ') : decade;
            }
            default: {
                return filterElem?.length ? filterElem?.toString() : 'N/A';
            }
        }
    };

    const selectedFilters = ({ data }) => {
        const triggerElement = <TextBodySm>{getFilterCount(data)}</TextBodySm>;

        return (
            <Tooltip
                triggerElement={triggerElement}
                triggerEvent={TooltipTriggerEvent.HOVER}
                isSecondary
                contentPadding={0}
                withArrow={false}
                placement={TooltipPlacement.BOTTOM}
                className="spg-p-xs cli-custom-grid-tooltip"
                width="380px"
            >
                <FiltersTooltipTable aria-label="simple table">
                    <tbody>
                        {Object.keys(data?.filters)?.length > 0 &&
                        Object.keys(data?.filters)
                            ?.filter((f) => !['asset_id', 'asset_name'].includes(f))
                            .map((element, index) => (
                                <tr key={index} className="spg-p-md">
                                    <td className="cli-capitalize-first">{element}</td>
                                    <td>{getSelectedFilters(data, element)}</td>
                                </tr>
                            ))}
                    </tbody>
                </FiltersTooltipTable>
            </Tooltip>
        );
    };

    const fileStatusCellRenderer = (params) => {
        const rowData = params?.data;
        const { exportTableData } = useContext(TableDataContext);

        return (
            <>
                {rowData.ready_for_download ? (
                    <Button
                        purpose={Purpose.NONE}
                        onClick={() => downloadActionClicked(rowData, exportTableData)}
                        className={rowData.className}
                        size={Size.SMALL}
                    >
                        {rowData.processing_status_text}
                    </Button>
                ) : (
                    <span className="spg-ml-sm">{rowData.processing_status_text}</span>
                )}
                {rowData?.processing_status_text === 'Download' ? (
                    <Button
                        purpose={Purpose.NONE}
                        onClick={() => downloadHistory(rowData)}
                        size={Size.SMALL}
                        style={{ marginLeft: '-12px' }}
                    >
                        &nbsp;({rowData.download_count})
                    </Button>
                ) : null}
            </>
        );
    };

    const refreshCellRenderer = (params) => {
        const { exportTableData } = useContext(TableDataContext);

        return (
            <IconButton
                icon={RESTORE}
                title="Refresh"
                onClick={() => refreshHandler(params.data, exportTableData)}
                size={Size.SMALL}
            />
        );
    };

    const frameworkComponents = useMemo(
        () => ({
            filtersRenderer: selectedFilters,
            fileStatusRenderer: fileStatusCellRenderer,
            refreshRenderer: refreshCellRenderer,
        }),
        [],
    );

    return (
        <>
            <CustomHeader
                searchTextPrev={pageData?.searchText}
                searchPlaceholder="Search by User Email"
                handleSearchSubmit={handleSubmit}
            />
            <DataTableGrid
                frameworkComponents={frameworkComponents}
                rowData={tableData}
                columnDefs={columnDefs}
                pagination
                totalItemsCount={count}
                onPageChange={(pageIndex) => handleChangePage(pageIndex)}
                onPageSizeChange={(newPageSize) => handleChangeRowsPerPage(newPageSize)}
            />
            <DownloadHistory
                open={showDownloadHistory}
                onClose={handleClose}
                selectedColumnData={selectedColumnData}
                tableData={downloadData}
                setTableData={setDownloadData}
                getDownloadHistoryList={getDownloadHistoryList}
                count={downloadDataCount}
            />
        </>
    );
}
