import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { DatePicker, generateDisabledDates } from '@spglobal/koi-datepicker';
import {
    Button,
    Icon,
    Select,
    FormGroup,
    useNotification,
    NotificationType,
} from '@spglobal/react-components';
import { useApiGet } from 'hooks/useApiGet';
import {
    getFoldersByCustomerID,
    generateReport,
    getAssetTagsByCustomerID,
} from 'services/exportService';
import riskFactorsService from 'services/riskFactorsService';
import { getUserDetails } from 'utils/rbacUtils';
import {
    OUTPUT_OPTIONS,
    RANGE_OPTIONS,
    DATAPOINT_OPTIONS,
    DATE_FORMAT,
    PHYSICAL_RISK_FACTOR_IDS,
    TRANSITION_RISK_FACTOR_IDS,
    EXPOSURE_HAZARD_IDS,
    DATAPOINT_OPTIONS_DMAAL,
    DATAPOINT_OPTIONS_EXPOSURE,
    HAZARD_ONLY,
} from 'utils/constants';
import toSnakeCase from 'utils/toSnakeCase';
import { Classes, Purpose } from '@spglobal/koi-helpers';
import { EXPORT } from '@spglobal/koi-icons';
import ExportSummaryModal from './ExportSummaryModal';
import ExportLimitDialog from './ExportLimitDialog';
import { isEmptyObject, transformDataObjForKoiSelect } from '../utils/koiIntegrationUtils';
import { InfoTooltip, TextBodyMd, TextBodySm } from '../components/shared';
import {
    CustomerSelect,
    DecadeSelect,
    FolderSelect,
    ScenarioSelect,
} from '../components/ReportSelects';

export default function ExportForm({
    selectedCustomer,
    setSelectedCustomer,
    getExportList,
    updateTableState,
    pageData,
}) {
    const [selectedOutput, setSelectedOutput] = useState({});
    const [selectedRange, setSelectedRange] = useState({});
    const [selectedScenarios, setSelectedScenarios] = useState([]);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [selectedFactors, setSelectedFactors] = useState([]);
    const [selectedAssetTags, setSelectedAssetTags] = useState([]);
    const [selectedDecades, setSelectedDecades] = useState([]);
    const [selectedDataPoints, setSelectedDataPoints] = useState([]);
    const [selectedCustomRange, setSelectedCustomRange] = useState({
        fromDate: null,
        toDate: null,
    });

    // DROPDOWNS
    const filteredPhysicalRiskFactorIds = PHYSICAL_RISK_FACTOR_IDS.filter((item) => item.id !== 30);
    const hazardIds = new Set(HAZARD_ONLY.map((hazard) => hazard.id));
    const PHYSICAL_RISK_FACTOR_IDS_WITHOUT_HAZARD_ONLY = PHYSICAL_RISK_FACTOR_IDS.filter(
        (risk) => !hazardIds.has(risk.id),
    );
    const riskFactorOptions =
        selectedOutput?.value === 'EXPO'
            ? EXPOSURE_HAZARD_IDS
            : selectedOutput?.value === 'PRFI' || selectedOutput?.value === 'PRIP'
            ? PHYSICAL_RISK_FACTOR_IDS_WITHOUT_HAZARD_ONLY
            : selectedOutput?.value === 'PRHM' || selectedOutput?.value === 'PRHM-B'
            ? PHYSICAL_RISK_FACTOR_IDS
            : TRANSITION_RISK_FACTOR_IDS;

    const dataPointOptions =
        selectedOutput?.value === 'PRHM' || selectedOutput?.value === 'PRHM-B'
            ? []
            : selectedOutput?.value === 'PRIP'
            ? [...DATAPOINT_OPTIONS_DMAAL]
            : selectedOutput?.value === 'EXPO'
            ? [...DATAPOINT_OPTIONS_EXPOSURE]
            : [...DATAPOINT_OPTIONS];

    const isDecadeDisabled = !!(
        selectedOutput?.value === 'PRHM-B' || selectedOutput?.value === 'PRIP'
    );

    const outputOptions = [...OUTPUT_OPTIONS];
    const dateRangeOptions = [...RANGE_OPTIONS];
    const [foldersList, setFoldersList] = useState([]);
    const [assetTags, setAssetTags] = useState([]);
    const [riskFactors, setRiskFactors] = useState([]);

    const [rangeErrors, setRangeErrors] = useState({ fromDate: '', toDate: '' });
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const { addNotification } = useNotification();
    const [isOpen, setIsOpen] = useState(false);
    const [isRequestFailedWith413, setIsRequestFailedWith413] = useState(false);

    const { data: customers } = useApiGet('api/customers', {});
    const { data: appConfigs } = useApiGet('api/appConfig', { keys: 'disabled_exports' });

    useEffect(() => {
        (async () => {
            if (selectedCustomer?.id) {
                const customerFolderIDs = await getFoldersByCustomerID(selectedCustomer?.id);
                const customerAssetTags = await getAssetTagsByCustomerID(selectedCustomer?.id);

                const foldersArr = customerFolderIDs?.result?.filter((folder) =>
                    Object.prototype.hasOwnProperty.call(folder, 'is_assigned')
                        ? folder?.is_assigned
                        : true,
                );

                customerAssetTags.push({ id: -1, name: 'Blank' });

                setFoldersList(foldersArr);
                setAssetTags(customerAssetTags);
            }

            const riskFactorsList = await riskFactorsService.getAll();
            setRiskFactors(riskFactorsList);
        })();
    }, [selectedCustomer]);

    const handleDatePickerChange = (range) => {
        const { fromDate, toDate } = range;
        const from = fromDate && new Date(fromDate);
        const to = toDate && new Date(toDate);
        let updatedRangeErr = { fromDate: '', toDate: '' };

        if (fromDate && toDate) {
            if (dayjs(from).isAfter(to, 'day')) {
                const err = 'To Date cannot be earlier than From Date.';
                updatedRangeErr = { fromDate: err, toDate: err };
            }
            if (dayjs(from).isAfter(new Date(), 'day'))
                updatedRangeErr = {
                    ...updatedRangeErr,
                    fromDate: 'From Date cannot be a future Date.',
                };
            if (dayjs(to).isAfter(new Date(), 'day'))
                updatedRangeErr = {
                    ...updatedRangeErr,
                    toDate: 'To Date cannot be a future Date.',
                };
        }

        setRangeErrors(updatedRangeErr);
        setSelectedCustomRange(range);
    };

    const getAssetTagsDropdown = () => {
        const options = assetTags?.map((option) =>
            transformDataObjForKoiSelect(option, { id: 'id', label: 'name', value: 'id' }),
        );

        const handleAssetTagsChange = (values) => {
            setSelectedAssetTags(values);
        };

        return (
            <FormGroup label="Select Tags" labelFor="asset-tags-select">
                <Select
                    id="asset-tags-select"
                    defaultValue={selectedAssetTags}
                    options={options}
                    onChange={handleAssetTagsChange}
                    isSearchable={false}
                    isMulti
                    placeholder=""
                />
            </FormGroup>
        );
    };

    const getRiskFactorSelect = () => {
        const handleRiskFactorsChange = (values) => {
            setSelectedFactors(values);
        };

        return (
            <FormGroup label="Select Risk Factor" labelFor="risk-factor-select" required>
                <Select
                    id="risk-factor-select"
                    options={riskFactorOptions}
                    defaultValue={selectedFactors}
                    onChange={handleRiskFactorsChange}
                    isSearchable={false}
                    isMulti
                    placeholder=""
                />
            </FormGroup>
        );
    };

    const getOutputSelect = () => {
        const list_of_disabled_reports = appConfigs?.results
            ?.find((i) => i.key == 'disabled_exports')
            .value.split(',');

        const options = outputOptions?.map((option) => ({
            ...option,
            disabled: option.value === list_of_disabled_reports?.find((x) => x == option.value),
        }));

        const defaultSelectedOutput = !isEmptyObject(selectedOutput) ? [selectedOutput] : [];

        const handleOutputChange = (values) => {
            const newOutput = values[0];
            if (newOutput.value !== selectedOutput.value) {
                setSelectedFactors([]);
            }
            setSelectedOutput(newOutput);
        };

        return (
            <FormGroup label="Select Output" labelfor="output-select" required>
                <Select
                    id="output-select"
                    defaultValue={defaultSelectedOutput}
                    options={options}
                    onChange={handleOutputChange}
                    isSearchable={false}
                    isMulti={false}
                    closeOnSelection
                    placeholder=""
                />
            </FormGroup>
        );
    };

    const getDataPointsSelect = () => {
        const handleDataPointsChange = (values) => {
            setSelectedDataPoints(values);
        };

        return (
            <FormGroup label="Select Other Datapoints" labelFor="datapoint-select">
                <Select
                    id="datapoint-select"
                    options={dataPointOptions}
                    defaultValue={selectedDataPoints}
                    onChange={handleDataPointsChange}
                    isSearchable={false}
                    isMulti
                    placeholder=""
                    disabled={dataPointOptions.length == 0}
                />
            </FormGroup>
        );
    };

    const getRangeSelect = () => {
        const rangeInfoTooltip = (
            <>
                <InfoTooltip iconClass="spg-ml-xs">
                    <TextBodySm className="spg-p-md">
                        Refers to the date that an asset record was created or updated in
                        Climanomics. By selecting a date range, you will be able to export the risk
                        outputs of assets that were either created or updated during your selected
                        range. There are different ranges to choose from. You may also create a
                        custom date range but selecting &quot;Custom&quot; from the dropdown menu.
                        If you select &quot;All&quot;, you will export all the assets in your
                        portfolio as long as you have been granted access to all folders by your
                        organization&#039;s administrator.
                    </TextBodySm>
                </InfoTooltip>
                <span className={`${Classes.LABEL_REQUIRED} spg-ml-xs`}>*</span>
            </>
        );

        const defaultSelectedRange = !isEmptyObject(selectedRange) ? [selectedRange] : [];

        const handleRangeChange = (values) => {
            setSelectedRange(values[0]);
        };

        return (
            <FormGroup label="Date Range" labelFor="range-select" labelInfo={rangeInfoTooltip}>
                <Select
                    id="range-select"
                    defaultValue={defaultSelectedRange}
                    options={dateRangeOptions}
                    onChange={handleRangeChange}
                    isSearchable={false}
                    isMulti={false}
                    closeOnSelection
                    placeholder=""
                />
            </FormGroup>
        );
    };

    const getCustomDatePicker = () => {
        const disabledDates = {
            after: new Date(),
        };

        const datePickerDisabledDates = generateDisabledDates(disabledDates);

        return (
            <>
                <div className="spg-col-4">
                    <FormGroup
                        label="From"
                        required
                        invalid={!!rangeErrors?.fromDate?.length}
                        feedbackText={rangeErrors?.fromDate}
                    >
                        <DatePicker
                            value={selectedCustomRange?.fromDate}
                            onChange={(newValue) =>
                                handleDatePickerChange({
                                    ...selectedCustomRange,
                                    fromDate: newValue,
                                })
                            }
                            format="MM/DD/YYYY"
                            placeholder="mm/dd/yyyy"
                            allowClear
                            disabledDate={datePickerDisabledDates}
                        />
                    </FormGroup>
                </div>
                <div className="spg-col-4">
                    <FormGroup
                        label="To"
                        required
                        invalid={!!rangeErrors?.toDate?.length}
                        feedbackText={rangeErrors?.toDate}
                    >
                        <DatePicker
                            value={selectedCustomRange?.toDate}
                            onChange={(newValue) =>
                                handleDatePickerChange({ ...selectedCustomRange, toDate: newValue })
                            }
                            format="MM/DD/YYYY"
                            placeholder="mm/dd/yyyy"
                            allowClear
                            disabledDate={datePickerDisabledDates}
                        />
                    </FormGroup>
                </div>
            </>
        );
    };

    const validateIsSubmitDisabled = () => {
        let isDisabled = false;
        if (
            !selectedCustomer?.label ||
            !selectedOutput?.label ||
            !selectedScenarios?.length ||
            !selectedFolders?.length ||
            !selectedFactors?.length ||
            (!selectedDecades?.length &&
                !(selectedOutput?.value === 'PRHM-B') &&
                !(selectedOutput?.value === 'PRIP')) ||
            !selectedRange?.value
        ) {
            isDisabled = true;
        }
        if (
            selectedRange?.value === 'CUSTOM' &&
            (!selectedCustomRange?.fromDate ||
                !selectedCustomRange?.toDate ||
                !!rangeErrors?.toDate?.length ||
                !!rangeErrors?.toDate?.length)
        )
            isDisabled = true;
        return isDisabled;
    };

    const getModifiedDate = (prevCount, prevValue) => {
        if (prevCount) return dayjs().subtract(prevCount, prevValue).format(DATE_FORMAT);
        return dayjs().format(DATE_FORMAT);
    };

    const getFormattedDate = () => {
        switch (selectedRange?.value) {
            case '3M':
                return { fromDate: getModifiedDate(3, 'months'), toDate: getModifiedDate() };
            case '6M':
                return { fromDate: getModifiedDate(6, 'months'), toDate: getModifiedDate() };
            case '1Y':
                return { fromDate: getModifiedDate(1, 'years'), toDate: getModifiedDate() };
            case '3Y':
                return { fromDate: getModifiedDate(3, 'years'), toDate: getModifiedDate() };
            case '5Y':
                return { fromDate: getModifiedDate(5, 'years'), toDate: getModifiedDate() };
            case 'CUSTOM':
                return {
                    fromDate: dayjs(new Date(selectedCustomRange?.fromDate)).format(DATE_FORMAT),
                    toDate: dayjs(new Date(selectedCustomRange?.toDate)).format(DATE_FORMAT),
                };
            default:
                return { fromDate: 'all', toDate: 'all' };
        }
    };

    const handleFormSubmit = async (isConfirm) => {
        const formattedDateObj = getFormattedDate();
        if (!isConfirm) {
            setShowSummaryModal(isConfirm);
        } else {
            const { name, email } = getUserDetails();
            const includeBlankTags = selectedAssetTags.find((tag) => tag.id === -1);
            const requestParams = {
                customer_name: selectedCustomer?.label.replace(/[/ ]/g, '_'),
                folder_ids: JSON.stringify([...selectedFolders?.map((item) => item?.id)]),
                export_type: selectedOutput?.value,
                output: selectedOutput?.label,
                scenario: JSON.stringify([...selectedScenarios?.map((item) => `${item?.value}`)]),
                decade: JSON.stringify([...selectedDecades?.map((item) => item?.value)]),
                risk_factor: JSON.stringify([...selectedFactors?.map((item) => `${item?.id}`)]),
                asset_tags: JSON.stringify([
                    ...selectedAssetTags
                        ?.filter((item) => item.id !== -1) // filtering out Blank option if user selected it
                        .map((item) => item?.id),
                ]),
                other_datapoints: JSON.stringify([
                    ...selectedDataPoints?.map((item) => {
                        if (item.label === 'Street Address') return 'location_street_address';

                        if (item.label === 'City/Town') return 'location_city';

                        if (item.label === 'State/Province') return 'location_state';

                        if (item.label === 'Postal Code') return 'location_postal_code';

                        if (item.label === 'Date Created') return 'asset_created_date';

                        if (item.label === 'Date Updated') return 'asset_update_date';

                        if (item.label === 'Country') return 'location_country';

                        if (item.label === 'Location Reference ID') return 'location_reference_id';

                        return toSnakeCase(item.label);
                    }),
                ]),
                from_date: formattedDateObj?.fromDate,
                to_date: formattedDateObj?.toDate,
                report_type: 'generate_report',
                include_blank_tags: !!includeBlankTags,
                user_name: name,
                user_email: email,
            };
            try {
                const response = await generateReport(selectedCustomer?.id, requestParams);
                const result = await getExportList(0, pageData.rowsPerPage);
                updateTableState(result);
                addNotification(response, NotificationType.SUCCESS);
            } catch (err) {
                setIsRequestFailedWith413(err?.message === 'Request failed with status code 413');
                addNotification(err?.response?.data || err?.message, NotificationType.ERROR);
            }

            setShowSummaryModal(false);
            setIsOpen(true);
        }
    };

    const dialogParam = {
        handleClose: () => setIsOpen(false),
        open: isOpen,
    };

    return (
        <>
            <>
                <div className="spg-row">
                    <div className="spg-col-4">
                        <CustomerSelect
                            customers={customers}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                            setAssetTags={setAssetTags}
                            setSelectedFolders={setSelectedFolders}
                            setSelectedAssetTags={setSelectedAssetTags}
                        />
                    </div>
                    <div className="spg-col-4">
                        <FolderSelect
                            foldersList={foldersList}
                            selectedFolders={selectedFolders}
                            setSelectedFolders={setSelectedFolders}
                        />
                    </div>
                    <div className="spg-col-4">{getOutputSelect()}</div>
                </div>
                <div className="spg-row">
                    <div className="spg-col-4">
                        <ScenarioSelect
                            selectedScenarios={selectedScenarios}
                            setSelectedScenarios={setSelectedScenarios}
                        />
                    </div>
                    <div className="spg-col-4">
                        <DecadeSelect
                            selectedDecades={selectedDecades}
                            setSelectedDecades={setSelectedDecades}
                            isMulti={true}
                            isDisabled={isDecadeDisabled}
                        />
                    </div>
                    <div className="spg-col-4">{getRiskFactorSelect()}</div>
                </div>
                <div className="spg-row">
                    <div className="spg-col-4">{getDataPointsSelect()}</div>
                    <div className="spg-col-4">{getAssetTagsDropdown()}</div>
                    <div className="spg-col-4">{getRangeSelect()}</div>
                </div>
                {selectedRange?.value === 'CUSTOM' && (
                    <div className="spg-row">{getCustomDatePicker()}</div>
                )}

                <div className="spg-d-flex spg-justify-end spg-mt-md spg-mb-md">
                    <Button
                        type="submit"
                        id="generateExportButton"
                        leftIcon={<Icon icon={EXPORT} disableAutomaticHoverState />}
                        purpose={Purpose.PRIMARY}
                        onClick={() => setShowSummaryModal(true)}
                        disabled={validateIsSubmitDisabled()}
                    >
                        GENERATE REPORT
                    </Button>
                </div>
            </>

            {isRequestFailedWith413 && <ExportLimitDialog {...dialogParam} />}

            {showSummaryModal && (
                <ExportSummaryModal
                    selectedCustomer={selectedCustomer}
                    selectedOutput={selectedOutput}
                    selectedRange={selectedRange}
                    selectedCustomRange={selectedCustomRange}
                    selectedScenarios={selectedScenarios}
                    selectedFactors={selectedFactors}
                    selectedFolders={selectedFolders}
                    selectedDecades={selectedDecades}
                    selectedDataPoints={selectedDataPoints}
                    selectedAssetTags={selectedAssetTags}
                    onConfirm={(value) => handleFormSubmit(value)}
                />
            )}
        </>
    );
}
