import React from 'react';
import realAssetsIcon from './assets/icon--real-assets.svg';

export default function IconBand({ iconType }) {
    return (
        <div>
            <div
                style={{
                    backgroundImage: `url(${
                        iconType === 'Climate Risk' || iconType === 'Nature Risk'
                            ? realAssetsIcon
                            : ''
                    })`,
                    backgroundColor: '#212C37',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '100%',
                    width: '3.75rem',
                }}
                data-testid={iconType ? `${iconType.split(' ').join('')}-icon` : 'iconBand-icon'}
            ></div>
        </div>
    );
}
